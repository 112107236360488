<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info_box">
            <p>
              {{ langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title) }} -
              Special Page
            </p>
            <DepositInfo :instData="instData" :noteData="noteData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">
              {{
                $t('deposit.cps', {
                  name: paymentDetails.merchant_variable.label
                    ? langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.label)
                    : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
                })
              }}
            </p>
            <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="cpsRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="tradeAccountCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, cpsForm.amount)" prop="amount">
                      <numeric-input
                        v-model="cpsForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>

                <!-- Attach Variables -->
                <ul class="clearfix" v-if="cpsForm.attachVariables.length > 0">
                  <li v-for="(type, index) in cpsForm.attachVariables" :key="type.key">
                    <!-- Input Field -->
                    <el-form-item
                      :label="type.field_name"
                      :key="type.key"
                      :prop="'attachVariables.' + index + '.value'"
                      v-if="type.field_type == 'input_field'"
                      :rules="type.type == 'email' ? emailRules : inputRules"
                    >
                      <el-input :data-testid="type.key" v-model="cpsForm.attachVariables[index].value"></el-input>
                    </el-form-item>

                    <!-- Dropdown List -->
                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.field_name != 'depositor'"
                      :label="$t('common.field.bankName')"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                      >
                        <el-option
                          :label="value.bank_name"
                          :value="value.bank_code"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.field_name == 'depositor'"
                      :label="$t('common.field.bankName')"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                      >
                        <el-option
                          :label="value.desc"
                          :value="value.value"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </ul>

                <ul class="clearfix">
                  <li class="data" v-if="showExchangeRates">
                    <p>
                      {{
                        $t('deposit.default.rate.rate', {
                          oldCurrency: accountCurrency,
                          newCurrency: paymentDetails.actual_currency_code
                        })
                      }}
                      <span>{{ rate }}</span>
                    </p>
                    <p>
                      {{ paymentDetails.actual_currency_code }}: <span>{{ rateChange }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="cpsForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitCpsForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
            <div v-html="bankHTML"></div>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo" :class="paymentLogo(paymentDetails.payment_method)"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import mixin from '@/mixins';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositInfo from '@/components/payment/DepositInfo.vue';

export default {
  name: 'cps',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin, cpsMixin],
  data() {
    return {
      rate: 0.0,
      redirectType: 1,
      accountCurrency: '',
      exchangeRates: false,
      showExchangeRates: false,
      noteData: [this.$t('deposit.crypto.note')],
      showNote: false
    };
  },
  computed: {
    instData() {
      let data = [];

      if (this.walletPaymentTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst2', {
            method: this.langTranslate(
              'deposit.default.channels.',
              this.paymentDetails.merchant_variable?.description_title
            )
          }),
          this.$t('deposit.reminder.inst3', {
            method: this.langTranslate(
              'deposit.default.channels.',
              this.paymentDetails.merchant_variable?.description_title
            )
          })
        ];
      } else if (this.bankTransferTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst12'),
          this.$t('deposit.reminder.inst13')
        ];
      } else if (this.upiTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst19'),
          this.$t('deposit.reminder.inst10')
        ];
      } else if (this.localDepositorTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.localdepositor.inst1'),
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.localdepositor.inst2'),
          this.$t('deposit.localdepositor.inst3')
        ];
      } else {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst7', {
            method: this.cryptoPayment.includes(this.paymentDetails.payment_method)
              ? this.paymentDetails.actual_currency_code
              : this.langTranslate(
                  'deposit.default.channels.',
                  this.paymentDetails.merchant_variable?.description_title
                )
          }),
          this.$t('deposit.reminder.inst8', {
            method: this.cryptoPayment.includes(this.paymentDetails.payment_method)
              ? this.paymentDetails.actual_currency_code
              : this.langTranslate(
                  'deposit.default.channels.',
                  this.paymentDetails.merchant_variable?.description_title
                )
          })
        ];
      }

      return data;
    }
  },
  watch: {
    accountCurrency() {
      this.determineExchangeRate();
      this.updateLimit();
    }
  },
  mounted() {
    // show deposit note for crypto payment
    if (this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
      this.showNote = true;
    }

    this.cpsForm.attachVariables = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.hasOwnProperty('field_type'))
      : '';
    this.cpsForm.mandatory = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.length > 0)?.[0]
      : '';
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
